// This component allows for a separation of concerns by making the TopHeader component
// mostly presentational for easier usage with Storybook and independent from Astro packages.

import { withNavigate } from '~/components/NavigateHOC/NavigateHOC';
import { signIn, signOut, signUp } from '~/utils/auth';
import { TopHeader } from './TopHeader';

import type { IProps as ITopHeaderProps } from './TopHeader';

const TopHeaderWithNavigate = withNavigate(TopHeader);

export const TopHeaderContainer = (props: ITopHeaderProps) => {
  return (
    <TopHeaderWithNavigate
      {...props}
      signIn={signIn}
      signOut={signOut}
      signUp={signUp}
    />
  );
};
